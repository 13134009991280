import React from "react";
import ContactLanding from "../ContactLanding";
import Footer from "../shared/Footer";

const Contact = () => {
  return (
    <div className="contact">
      <ContactLanding />;
      <Footer />;
    </div>
  );
};

export default Contact;
