import React from "react";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useState } from "react";

const TeamDivisions = () => {
  const consulting = [
    {
      firstName: "Ava",
      lastName: "Maclaughlin",
      title: "Project Manager",
    },
    {
      firstName: "Cameron",
      lastName: "Ruh",
      title: "Project Manager",
    },
    {
      firstName: "Diya",
      lastName: "Patel",
      title: "Project Manager",
    },
    {
      firstName: "Nikhil",
      lastName: "Aggarwal",
      title: "Project Manager",
    },
    {
      firstName: "Veronica",
      lastName: "Xu",
      title: "Project Manager",
    },
    {
      firstName: "Lisa",
      lastName: "Noody",
      title: "Project Manager",
    },
    {
      firstName: "Jed",
      lastName: "Dietrich",
      title: "Business Analyst",
    },
    {
      firstName: "Chloe",
      lastName: "Treanor",
      title: "Business Analyst",
    },
    {
      firstName: "Leila",
      lastName: "Hamdan",
      title: "Business Analyst",
    },
    {
      firstName: "Finn",
      lastName: "Murphy",
      title: "Business Analyst",
    },
    {
      firstName: "Luke",
      lastName: "Ronayne",
      title: "Business Analyst",
    },
    {
      firstName: "Joe",
      lastName: "Walker",
      title: "Business Analyst",
    },
    {
      firstName: "Audrey",
      lastName: "Biles",
      title: "Business Analyst",
    },
    {
      firstName: "Natalie",
      lastName: "Cartwright",
      title: "Business Analyst",
    },
    {
      firstName: "Eileen",
      lastName: "Chen",
      title: "Business Analyst",
    },
    {
      firstName: "Christopher",
      lastName: "Chenet",
      title: "Business Analyst",
    },
    {
      firstName: "Joshua",
      lastName: "Fink",
      title: "Business Analyst",
    },
    {
      firstName: "Abeer",
      lastName: "Gupta",
      title: "Business Analyst",
    },
    {
      firstName: "Amisha",
      lastName: "Gupta",
      title: "Business Analyst",
    },
    {
      firstName: "Alexis",
      lastName: "Kim",
      title: "Business Analyst",
    },
    {
      firstName: "Wilson",
      lastName: "Leung",
      title: "Business Analyst",
    },
    {
      firstName: "Matthew",
      lastName: "Mao",
      title: "Business Analyst",
    },
    {
      firstName: "Alexiya",
      lastName: "Mikerina",
      title: "Business Analyst",
    },
    {
      firstName: "Saba",
      lastName: "Alfred",
      title: "Business Analyst",
    },
    {
      firstName: "Alyssa",
      lastName: "Kim",
      title: "Business Analyst",
    },
  ]; //0
  const analytics = [
    {
      firstName: "Sameer",
      lastName: "Tirumala",
      title: "VP of Analytics",
    },
    {
      firstName: "Annika",
      lastName: "Lin",
      title: "VP of Analytics",
    },
    {
      firstName: "Meredith",
      lastName: "Lou",
      title: "VP of Analytics",
    },
    {
      firstName: "Chris",
      lastName: "Tengey",
      title: "VP of Analytics",
    },
    {
      firstName: "Maggie",
      lastName: "Shen",
      title: "VP of Analytics",
    },
    {
      firstName: "Aayush",
      lastName: "Murarka",
      title: "Data Analyst",
    },
    {
      firstName: "Cameron",
      lastName: "Worden",
      title: "Data Analyst",
    },
    {
      firstName: "Grace",
      lastName: "St. Jeanos",
      title: "Data Analyst",
    },
    {
      firstName: "Lucas",
      lastName: "Lin",
      title: "Data Analyst",
    },
    {
      firstName: "Sazan",
      lastName: "Khalid",
      title: "Data Analyst",
    },
  ];
  const training = [
    {
      firstName: "Veronica",
      lastName: "Xu",
      title: "VP of Technology",
    },
    {
      firstName: "Jason",
      lastName: "Yi",
      title: "VP of Technology",
    },
    {
      firstName: "Matt",
      lastName: "Jordan",
      title: "VP of Technology",
    },
    {
      firstName: "Allison",
      lastName: "Kim",
      title: "VP of Technology",
    },
    {
      firstName: "Amanda",
      lastName: "Hao",
      title: "VP of Technology",
    },
    {
      firstName: "Nikhil",
      lastName: "Aggarwal",
      title: "Instructor",
    },
    {
      firstName: "Tess",
      lastName: "Prutow",
      title: "Instructor",
    },
    {
      firstName: "Aayush",
      lastName: "Murarka",
      title: "Instructor",
    },
    {
      firstName: "Zach",
      lastName: "Shah",
      title: "Instructor",
    },
    {
      firstName: "Grace",
      lastName: "St. Jeanos",
      title: "Instructor",
    },
    {
      firstName: "Sophie",
      lastName: "Liu",
      title: "Instructor",
    },
    {
      firstName: "Allison",
      lastName: "Sacamano",
      title: "Instructor",
    },
    {
      firstName: "Cameron",
      lastName: "Worden",
      title: "Instructor",
    },
  ];
  const [division, setDivision] = useState(0);
  return (
    <div className="team-divisions">
      <div className="container">
        <h1 className="team-divisions-title">Our Divisions</h1>
        <div className="team-divisions-buttons">
          <div
            className={
              division === 0
                ? "team-divisions-button-active"
                : "team-divisions-button"
            }
            onClick={() => setDivision(0)}>
            <img src="/svgs/divisions/consulting.svg" alt="consulting" />
            <h5>Consulting</h5>
          </div>
          <div
            className={
              division === 1
                ? "team-divisions-button-active"
                : "team-divisions-button"
            }
            onClick={() => setDivision(1)}>
            <img src="/svgs/divisions/analytics.svg" alt="consulting" />
            <h5>Analytics</h5>
          </div>
          <div
            className={
              division === 2
                ? "team-divisions-button-active"
                : "team-divisions-button"
            }
            onClick={() => setDivision(2)}>
            <img src="/svgs/divisions/training.svg" alt="consulting" />
            <h5>Training</h5>
          </div>
        </div>
        <div className="team-divisions-members">
          {division === 0 &&
            consulting.map((member, index) => {
              return (
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  animateOnce={true}
                  delay={index * 40}
                  key={member.name}
                  className="team-division-member">
                  <img
                    src={
                      "/headshots-2023/" +
                      member.lastName +
                      ", " +
                      member.firstName +
                      " Large.jpeg"
                    }
                    alt={member.name}
                  />
                  <h2>{member.firstName + " " + member.lastName}</h2>
                  <h3>{member.title}</h3>
                </AnimationOnScroll>
              );
            })}
          {division === 1 &&
            analytics.map((member, index) => {
              return (
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  animateOnce={true}
                  delay={index * 40}
                  key={member.name}
                  className="team-division-member">
                  <img
                    src={
                      "/headshots-2023/" +
                      member.lastName +
                      ", " +
                      member.firstName +
                      " Large.jpeg"
                    }
                    alt={member.name}
                  />
                  <h2>{member.firstName + " " + member.lastName}</h2>
                  <h3>{member.title}</h3>
                </AnimationOnScroll>
              );
            })}
          {division === 2 &&
            training.map((member, index) => {
              return (
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  animateOnce={true}
                  delay={index * 40}
                  key={member.name}
                  className="team-division-member">
                  <img
                    src={
                      "/headshots-2023/" +
                      member.lastName +
                      ", " +
                      member.firstName +
                      " Large.jpeg"
                    }
                    alt={member.name}
                  />
                  <h2>{member.firstName + " " + member.lastName}</h2>
                  <h3>{member.title}</h3>
                </AnimationOnScroll>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TeamDivisions;
