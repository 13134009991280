import React from "react";
import { useEffect } from "react";
const Alumni = () => {
  const images = [
    "/alumni/accenture.png",
    "/alumni/amazon.png",
    "/alumni/aon.png",
    "/alumni/ares.png",
    "/alumni/bain.png",
    "/alumni/bcg-logo.webp",
    "/alumni/BrownAdvisory-logo.png",
    "/alumni/connect-gen.png",
    "/alumni/capital-one.png",
    "/alumni/Deloitte.png",
    "/alumni/deutsche-bank.png",
    "/alumni/employers.png",
    "/alumni/fanduel.png",
    "/alumni/freddie-mac.png",
    "/alumni/fti.png",
    "/alumni/harbourvest.png",
    "/alumni/houlihan-lokey.png",
    "/alumni/ibm.png",
    "/alumni/kaiser.png",
    "/alumni/liberty-mutual.webp",
    "/alumni/loreal.png",
    "/alumni/medidata.png",
    "/alumni/microsoft.webp",
    "/alumni/nih.png",
    "/alumni/pwc.png",
    "/alumni/qualtrics.png",
    "/alumni/Raymond-James.png",
    "/alumni/regeneronlogo.png",
    "/alumni/salesforce.png",
    "/alumni/Tableau-Emblem.png",
    "/alumni/ubs.png",
  ];
  return (
    <div className="alumni">
      <h1>Our Alumni Network</h1>
      <h4>
        Our commitment to excellence helps us to build a strong, well-connected
        alumni network, giving current members valuable mentorship across a
        variety of fields.
      </h4>
      <div className="alumni-grid">
        {images.map((image, index) => (
          <div className="alumni-image" key={index}>
            <img src={image} alt="Company" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Alumni;
