import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/pages/Home";

import Contact from "./components/pages/Contact";
import Team from "./components/pages/Team";
import CabFair from "./components/pages/CabFair";
import "./styles/main.scss";
import Training from "./components/pages/Training";
import Services from "./components/pages/Services";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cabfair" element={<CabFair />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/team" element={<Team />} />
        <Route path="/training" element={<Training />} />
        <Route path="/services" element={<Services />} />
      </Routes>
    </Router>
  );
}

export default App;
