import React from "react";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const TeamBoardMembers = () => {
  const execBoard = [
    {
      firstName: "Reed",
      lastName: "Uhlik",
      title: "Chief Executive Officer",
      description:
        "Reed is a junior in the College double majoring in Computer Science and Economics. As CEO, he oversees the board and leads the club's strategic development.  This summer, Reed will be interning at the D.E. Shaw Group as a Systems Engineering Intern.  In his free time, he enjoys playing basketball, watching football, and designing websites",
      email: "rcu8@georgetown.edu",
      linkedin: "https://www.linkedin.com/in/reed-uhlik/",
    },
    {
      firstName: "Veronica",
      lastName: "Xu",
      title: "Chief Operating Officer",
      description:
        "Veronica is a sophomore in the College studying Psychology and Statistics. She is from Westford, MA, and is a proud New Englander. Previously, she interned at Nuance + Microsoft and will be joining Hilton as a data analytics intern this summer. Outside of Hoyalytics, she is passionate about mental health and is a member of Active Minds and a research assistant in Georgetown's Stress, Health, and Development Lab. In her free time, she enjoys the outdoors, traveling, playing basketball and volleyball, and listening to Spotify.",
      email: "vyx2@georgetown.edu",
      linkedin: "https://www.linkedin.com/in/veronicaxu157/",
    },
    {
      firstName: "Allison",
      lastName: "Kim",
      title: "Chief HR Officer",
      description:
        "Allison is a sophomore in the School of Health majoring in Healthcare Management and Policy and minoring in Cognitive Science. She is a data science intern at the Indian Health Service and a HR intern at Santé Health Systems. Outside of Hoyalytics, you can find her tinkering at the Makerhub, reaching nirvana at the Dharmic Meditation Center, or trekking on long journeys across the city by foot.",
      email: "ak2091@georgetown.edu",
      linkedin: "https://www.linkedin.com/in/allison-kim-dc/",
    },
    {
      firstName: "Nikhil",
      lastName: "Aggarwal",
      title: "Chief Consulting Officer",
      description:
        "Nikhil is a junior in the MSB majoring in Finance and Management. He is originally from Lorton, Virginia. Apart from Hoyalytics, Nikhil is an MSB Peer Ambassador and teaches chess to local elementary schoolers. In his free time, Nikhil enjoys agonizing over his fantasy football team, playing Mario Kart Wii with his friends, and listening to a wide variety of music.",
      email: "nva6@georgetown.edu",
      linkedin: "https://www.linkedin.com/in/nikhilvaggarwal/",
    },
    {
      firstName: "Maggie",
      lastName: "Shen",
      title: "Chief Analytics Officer",
      description:
        "Maggie is a sophomore in the college double majoring in Mathematics and Computer Science and minoring in Economics. She recently interned at PwC the previous summer. Outside of Hoyalytics, she enjoys doing photography, playing poker, and exploring new restaurants.",
      email: "xs196@georgetown.edu",
      linkedin: "https://www.linkedin.com/in/maggie-shen/",
    },
    {
      firstName: "Jason",
      lastName: "Yi",
      title: "Chief Training Officer",
      description:
        "Jason is a sophomore in the college studying Computer Science. He is a currently a student researcher at Georgetown under the Massive Data Institute researching privacy in data collection and has previously worked at the Beeck Center for Social Impact + Innovation investigating how AI impacts public benefits delivery. This summer, Jason will be working at Aidy a YC backed startup trying to make the grant application process easier. In his free time he enjoys cooking, eating, and anything food. You can also find him playing volleyball and discovering new music.",
      email: "jsy37@georgetown.edu",
      linkedin: "https://www.linkedin.com/in/jason-yi1/",
    },
  ];
  const directors = [
    {
      firstName: "Thomas",
      lastName: "Ostberg",
      title: "Director of Public Relations",
      email: "#",
      linkedin: "#",
    },
    {
      firstName: "Rohan",
      lastName: "Mistry",
      title: "Director of Client Recruitment",
      email: "#",
      linkedin: "#",
    },
    {
      firstName: "Armin",
      lastName: "Taheripour",
      title: "Director of Client Recruitment",
      email: "#",
      linkedin: "#",
    },
    {
      firstName: "Sarah",
      lastName: "Ackels",
      title: "Director of Client Recruitment",
      email: "#",
      linkedin: "#",
    },
    {
      firstName: "Caroline",
      lastName: "Box",
      title: "Director of Professional Development",
      email: "#",
      linkedin: "#",
    },
    {
      firstName: "Rhea",
      lastName: "Banerjee",
      title: "Director of DEI",
      email: "#",
      linkedin: "#",
    },
  ];
  return (
    <div className="team-board-members">
      <h1 className="team-board-members-title">Hoyalytics Executive Board</h1>
      <div className="team-board-members-content">
        <div className="team-board-members-exec">
          {execBoard.map((member) => {
            return (
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                className="team-member-exec"
                animateOnce={true}
                key={member.firstName}>
                <img
                  src={
                    "/headshots-2023/" +
                    member.lastName +
                    ", " +
                    member.firstName +
                    " Large.jpeg"
                  }
                  alt={member.firstName}
                />
                <div className="team-member-exec-info">
                  <div className="team-member-exec-info-left">
                    <h2>{member.firstName + " " + member.lastName}</h2>
                    <h3>{member.title}</h3>
                  </div>
                  <div className="team-member-exec-info-right">
                    <a href={`mailto:${member.email}`}>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                    <a href={member.linkedin} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                </div>
                <p>{member.description}</p>
              </AnimationOnScroll>
            );
          })}
        </div>
        <h1 className="team-board-members-title">Board of Directors</h1>
        <div className="team-board-members-directors">
          {directors.map((member, index) => {
            return (
              <AnimationOnScroll
                animateIn="animate__fadeInUp"
                className="team-member-director"
                animateOnce={true}
                key={member.firstName}>
                <img
                  src={
                    "/headshots-2023/" +
                    member.lastName +
                    ", " +
                    member.firstName +
                    " Large.jpeg"
                  }
                  alt={member.firstName}
                />
                <div className="team-member-exec-info">
                  <div className="team-member-exec-info-left">
                    <h2>{member.firstName + " " + member.lastName}</h2>
                    <h3>{member.title}</h3>
                  </div>
                </div>
                <p>{member.description}</p>
              </AnimationOnScroll>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamBoardMembers;
