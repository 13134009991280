import React from "react";
import Footer from "../shared/Footer";
import TrainingLanding from "../TrainingLanding";
import TrainingModules from "../TrainingModules";
import TrainingProgram from "../TrainingProgram";
const Training = () => {
  return (
    <div>
      <TrainingLanding />
      <TrainingProgram />
      <TrainingModules />
      <Footer />
    </div>
  );
};

export default Training;
