const courses = [
  {
    sectionTitle: "Q1",
    color: "#072F5F",
    modules: [
      {
        title: "Syntax and Structures",
        analystTraining: true,
        category: "Python",
        courseCode: "PYTH-I",
        img: "/svgs/python.png",
        instructor: "Aayush Murarka",
        description: "Discusses the fundamentals of Python and data cleaning",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeuoPSWIYp_d3m5-UIkMEymLi_pR19QkpNQ5QNOYGo-Arw1lA/viewform",
        day: "Mon 8-9, Wed 7-8",
        location: "ICC Auditorium",
        level: "Beginner",
        done: true,
      },
      {
        title: "Adv. Visualization Techniques",
        analystTraining: false,
        category: "Tableau",
        courseCode: "TAB-III",
        img: "/svgs/tableau.png",
        instructor: "Amanda Hao",
        description:
          "Become an expert in Tableau by learning how to complete complex operations and charts",
        link: "https://classroom.google.com/c/NjE3MzkyMDM0Njg2?cjc=bte4dln",
        day: "Tue 9-10",
        location: "MSB 130",
        level: "Beginner",
        done: true,
      },
      {
        title: "Introduction to Data Cleaning",
        analystTraining: false,
        category: "R",
        courseCode: "R-I",
        img: "/svgs/r_icon.svg",
        instructor: "Grace St. Jeanos",
        description: "Discusses the fundamentals of R and how to clean data",
        link: "https://classroom.google.com/c/NjE3Mzk0NzU2Mjcz?cjc=whmpwfk",
        day: "Sun 3-4",
        location: "MSB 130",
        level: "Beginner",
        done: true,
      },
      {
        title: "Fundamentals of Spreadsheets",
        analystTraining: false,
        category: "Excel",
        courseCode: "EXC-I",
        img: "/svgs/gs.png",
        instructor: "Shray Dewan",
        description:
          "Details the basics of Google Sheets/Excel, especially its design elements",
        link: "https://classroom.google.com/c/NjE3Mzk0NDE5OTcx?cjc=ttqgvya",
        day: "Tue 8-9",
        location: "MSB 145",
        level: "Beginner",
        done: true,
      },

      {
        title: "Intro to Web Development",
        analystTraining: false,
        category: "Lecture Series",
        courseCode: "WEB-I",
        img: "/svgs/figma.png",
        instructor: "Reed Uhlik",
        description:
          "Learn the basics of UI/UX design, HTML, and CSS to create a simple, static website.",
        link: "https://classroom.google.com/c/NjE3Mzk0MTgxOTky?cjc=3qepozs",
        day: "Sun 2-3",
        location: "MSB 130",
        level: "Beginner",
        done: true,
      },

      {
        title: "Soft Skills For Consultants",
        analystTraining: false,
        category: "Lecture Series",
        courseCode: "MISC",
        img: "/svgs/logo.svg",
        instructor: "Nikhil Aggarwal",
        description:
          "Discusses several areas of soft skills that consultants use on an everyday basis",
        link: "https://classroom.google.com/c/NjE3MzkyNjA5NTEw?cjc=p7lp3mk",
        day: "Mon 8-9",
        location: "MSB 140",
        level: "Beginner",
        done: true,
      },
      {
        title: "Life Skills",
        analystTraining: false,
        category: "Lecture Series",
        courseCode: "MISC",
        img: "/svgs/logo.svg",
        instructor: "Caroline Box",
        description: "Explores important life skills that Caroline has learned",
        link: "https://classroom.google.com/c/NjE3MzkzOTM1Mjky?cjc=y7bgbrr",
        day: "Sun 5-6",
        location: "MSB 140",
        level: "Beginner",
        done: true,
      },
    ],
  },
  {
    sectionTitle: "Q2",
    color: "#1261A0",
    modules: [
      {
        title: "Dataframes and Project Applications",
        analystTraining: true,
        category: "Python",
        courseCode: "PYTH-II",
        img: "/svgs/python.png",
        instructor: "Allison Sacamano",
        description:
          "Applies Python fundamentals and Pandas to project-based learning",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeuoPSWIYp_d3m5-UIkMEymLi_pR19QkpNQ5QNOYGo-Arw1lA/viewform",
        day: "Mon 9-10, Wed 7-8",
        location: "MSB 140",
        level: "Beginner",
        done: true,
      },
      {
        title: "Interactive Projects in Tableau",
        analystTraining: false,
        category: "Tableau",
        courseCode: "TAB-IV",
        img: "/svgs/tableau.png",
        instructor: "Shray Dewan",
        description:
          "Outlines how to make visualizations more interactive and how to create Tableau projects to publish",
        link: "#",
        day: "Tue 9-10",
        location: "MSB 130",
        level: "Beginner",
        done: true,
      },
      {
        title: "Data Structures and Statistics",
        analystTraining: false,
        category: "R",
        courseCode: "R-II",
        img: "/svgs/r_icon.svg",
        instructor: "Cameron Worden",
        description:
          "Details various topics, including joining data frames, creating data structures, and testing significances",
        link: "https://classroom.google.com/c/NjE3Mzk0NzU2Mjcz?cjc=whmpwfk",
        day: "Sun 3-4",
        location: "MSB 130",
        level: "Beginner",
        done: true,
      },
      {
        title: "Data Analysis & Visualization",
        analystTraining: false,
        category: "Excel",
        courseCode: "EXC-II",
        img: "/svgs/excel_icon.svg",
        instructor: "Shray Dewan",
        description: "Discusses more advanced, useful techniques in Excel",
        link: "https://classroom.google.com/c/NjE3Mzk0NDE5OTcx?cjc=ttqgvya",
        day: "Tue 8-9",
        location: "MSB 145",
        level: "Beginner",
        done: true,
      },
      {
        title: "React JS Web Development",
        analystTraining: false,
        category: "Lecture Series",
        courseCode: "WEB-II",
        img: "/svgs/react.png",
        instructor: "Reed Uhlik",
        description:
          "Learn how to create dynamic websites using React, enabling interactivity and responsiveness.",
        link: "https://classroom.google.com/c/NjE3Mzk0MTgxOTky?cjc=3qepozs",
        day: "Sun 2-3",
        location: "MSB 130",
        level: "Beginner",
        done: true,
      },
      {
        title: "iOS App Development",
        analystTraining: false,
        category: "Lecture Series",
        courseCode: "IOS-1",
        img: "/svgs/swift.svg",
        instructor: "Amanda Hao",
        description: "Discusses how to create an iOS app",
        link: "https://classroom.google.com/c/NjE3Mzk0Mzk0MDYz?cjc=xsm24gh",
        day: "Sun 5-6",
        location: "MSB 140",
        level: "Beginner",
        done: true,
      },
      {
        title: "Advanced ML Topics",
        analystTraining: false,
        category: "Lecture Series",
        courseCode: "MISC",
        img: "/svgs/logo.svg",
        instructor: "Jason Yi",
        description:
          "Details more complex topics in supervised and unsupervised machine learning",
        link: "https://classroom.google.com/c/NjE3Mzk0NzUwNjE2?cjc=2s6bff5",
        day: "Mon 8-9",
        location: "MSB 140",
        level: "Beginner",
        done: true,
      },
    ],
  },
  {
    sectionTitle: "Q3",
    color: "#3895D3",
    modules: [
      {
        title: "Introduction to Machine Learning",
        analystTraining: true,
        category: "Python",
        courseCode: "PYTH-III",
        img: "/svgs/python.png",
        instructor: "Jason Yi",
        description:
          "Introduces the concepts of unsupervised and supervised learning",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeuoPSWIYp_d3m5-UIkMEymLi_pR19QkpNQ5QNOYGo-Arw1lA/viewform",
        day: "Mon 9-10, Wed 7-8",
        location: "MSB 140",
        level: "Beginner",
      },
      {
        title: "Introduction to Visualizations",
        analystTraining: true,
        category: "Tableau",
        courseCode: "TAB-I",
        img: "/svgs/tableau.png",
        instructor: "Sophie Liu",
        description: "Discusses the best practices for visualizing data.",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeuoPSWIYp_d3m5-UIkMEymLi_pR19QkpNQ5QNOYGo-Arw1lA/viewform",
        day: "Mon 8-9, Tue 8-9",
        location: "MSB 140, 150",
        level: "Beginner",
      },
      {
        title: "Data Visualization",
        analystTraining: false,
        courseCode: "R-III",
        category: "R",
        img: "/svgs/r_icon.svg",
        instructor: "Tess Prutow",
        description: "Learn how to make interesting graphs unique to R.",
        link: "https://classroom.google.com/c/NjE3Mzk0NzU2Mjcz?cjc=whmpwfk",
        day: "Sun 3-4",

        location: "MSB 130",
        level: "Intermediate",
      },
      {
        title: "Data Analysis & Visualization",
        analystTraining: false,
        category: "Excel",
        courseCode: "EXC-III",
        img: "/svgs/excel_icon.svg",
        instructor: "Abby Quarm",
        description:
          "Details how to automate functions in Excel to make work quicker",
        link: "https://classroom.google.com/c/NjE3Mzk0NDE5OTcx?cjc=ttqgvya",
        day: "Tue 7-8",
        location: "MSB 145",
        level: "Beginner",
      },
      {
        title: "Querying & Combining Data",
        analystTraining: false,
        courseCode: "SQL-I",
        category: "SQL",
        img: "/svgs/sql_icon.svg",
        instructor: "Zach Shah",
        description:
          "Learn how to query and combine data using SQL, the most popular database language.",
        link: "https://classroom.google.com/c/NjE3Mzk0NzQyNjY2?cjc=rykbggd",
        day: "Sun 2-3",
        location: "MSB 150",
        level: "Intermediate",
      },
    ],
  },
  {
    sectionTitle: "Q4",
    color: "#58CCED",
    modules: [
      {
        title: "Advanced Data Science Techniques",
        analystTraining: true,
        category: "Python",
        courseCode: "PYTH-IV",
        img: "/svgs/python.png",
        instructor: "Zach Shah",
        description: "Applies machine learning concepts to exploratory project",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeuoPSWIYp_d3m5-UIkMEymLi_pR19QkpNQ5QNOYGo-Arw1lA/viewform",
        day: "Tue 7-8, Wed 7-8",
        location: "MSB 140, 160",
        level: "Beginner",
      },
      {
        title: "Introduction to Data Consulting",
        analystTraining: true,
        category: "Tableau",
        courseCode: "TAB-II",
        img: "/svgs/tableau.png",
        instructor: "Sophie Liu",
        description: "Applies data visualization to live business case.",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSeuoPSWIYp_d3m5-UIkMEymLi_pR19QkpNQ5QNOYGo-Arw1lA/viewform",
        day: "Mon 8-9, Tue 8-9",
        location: "MSB 140, 150",
        level: "Beginner",
      },
      {
        title: "Supervised Machine Learning",
        analystTraining: false,
        courseCode: "R-IV",
        category: "R",
        img: "/svgs/r_icon.svg",
        instructor: "Amanda Hao",
        description:
          "Analyzes the relationship between variables using regression analysis.",
        link: "https://classroom.google.com/c/NjE3Mzk0NzU2Mjcz?cjc=whmpwfk",
        day: "Sun 3-4",
        location: "MSB 130",
        level: "Advanced",
      },
      {
        title: "Databases and Functions",
        analystTraining: false,
        courseCode: "SQL-II",
        category: "SQL",
        img: "/svgs/sql_icon.svg",
        instructor: "Jason Yi",
        description:
          "Connect to databases and create functions using SQL to power backend data services.",
        link: "https://classroom.google.com/c/NjE3Mzk0NzQyNjY2?cjc=rykbggd",
        day: "Sun 2-3",
        location: "MSB 150",
        level: "Intermediate",
      },
    ],
  },
];

export default courses;
